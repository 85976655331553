.loading-div {
    text-align: center;
    font-size: 34px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 10px;
    align-content: center;
}

.inner-div {
    width: 100%;
    display: flex;
    margin-top: 30px;
    flex-direction: row;
    justify-content: space-around;
    height: 50%;
    align-content: center;
}

.lds-dual-ring {
    display: inline-block;
    width: 64px;
    height: 64px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid black;
    border-color: black transparent black transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
  
